import ReactOnRails from 'react-on-rails';

import UserSearchBar from '../bundles/Main/components/UserSearchBar';
import { UserMenu } from '../bundles/Main/components/UserMenu';

// This is how react_on_rails can see the components in the browser.
ReactOnRails.register({
  UserSearchBar,
  UserMenu,
});
