import * as React from 'react';

interface PropTypes {
  type: 'magnifying-glass' | 'exclamation-circle' | 'spinner';
  className?: string;
}
export default function Icon(props: PropTypes) {
  const { type } = props;
  const className = `w-6 h-6 pointer-events-none absolute
      transform top-1/2 -translate-y-1/2 ${props.className}`;

  switch (type) {
    case 'magnifying-glass':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={'2'}
          className={className}
        >
          <path
            fill="none"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      );

    case 'spinner':
      return (
        <svg
          version="1.1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="6 6 38 38"
          className={className}
        >
          <path d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      );

    case 'exclamation-circle':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={`h-6 w-6 ${className}`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
      );
  }
}
